import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Wave from "./components/wave.js";

function App() {
  return (
    <div className="App">
      <div>
        <Router>
          <Routes>
            <Route path="/projects" element={<Wave />} exact />
            <Route path="/affiliated" element={<Wave />} exact />
          </Routes>
        </Router>
      </div>
    </div>
  );
}

export default App;
