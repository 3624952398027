// import { Link } from "react-router-dom";

<link
  href="https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;700&display=swap"
  rel="stylesheet"
></link>;
function wave() {
  return (
    <div>
      <div class="wave">
        {/* <a href="localcloud" class="no-underline">
          <div class="container">
            <p class="first">LocalCloud</p>
            <h2>LocalCloud</h2>
            <p class="second">LocalCloud | Storage | share</p>
          </div>
        </a> */}
      </div>
      <div class="flexbox">
        <div class="flexcard flexcardBlue">
          <div class="flexcardNumber flexcardNumberBlue">01</div>
          {/* <div class="flex flexcardTitle">Localcloud</div> */}
          <a href="/localcloud/" class="no-underline">
            <div class="flex flexcardText">
              <div>
                <h2>LocalCloud</h2>
                <p class="second">LocalCloud | Storage | share</p>
              </div>
            </div>
            <div class="flex flexcardImg">
              <img
                class="flexcardimgItem"
                src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
                alt=""
              />
            </div>
          </a>
        </div>
        <div class="flexcard flexcardBlue">
          <div class="flexcardNumber flexcardNumberBlue">02</div>
          {/* <div class="flex flexcardTitle">Localcloud</div> */}
          <a href="/cryptoutils/" class="no-underline">
            <div class="flex flexcardText">
              <div>
                <h2>CryptoUtils</h2>
                <p class="second">Risk | Reward | Automate</p>
              </div>
            </div>
            <div class="flex flexcardImg">
              <img
                class="flexcardimgItem"
                src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
                alt=""
              />
            </div>
          </a>
        </div>
        {/* <div class="flexcard flexcardGreen">
          <div class="flexcardNumber flexcardNumberGreen">02</div>
          <div class="flex flexcardTitle">Title</div>
          <div class="flex flexcardText">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Recusandae, temporibus consectetur? Iure id nam fuga asperiores
            repellat accusantium exercitationem nemo?
          </div>
          <div class="flex flexcardImg">
            <img
              class="flexcardimgItem"
              src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
              alt=""
            />
          </div>
        </div>
        <div class="flexcard flexcardOrange">
          <div class="flexcardNumber flexcardNumberOrange">03</div>
          <div class="flex flexcardTitle">Title</div>
          <div class="flex flexcardText">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Recusandae, temporibus consectetur? Iure id nam fuga asperiores
            repellat accusantium exercitationem nemo?
          </div>
          <div class="flex flexcardImg">
            <img
              class="flexcardimgItem"
              src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
              alt=""
            />
          </div>
        </div>
        <div class="flexcard flexcardPink">
          <div class="flexcardNumber flexcardNumberPink">04</div>
          <div class="flex flexcardTitle">Title</div>
          <div class="flex flexcardText">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            Recusandae, temporibus consectetur? Iure id nam fuga asperiores
            repellat accusantium exercitationem nemo?
          </div>
          <div class="flex flexcardImg">
            <img
              class="flexcardimgItem"
              src="https://cdn.pixabay.com/photo/2017/01/10/23/01/seo-1970475_960_720.png"
              alt=""
            />
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default wave;
